import React from "react"
import { graphql } from "gatsby"
import queryString from "query-string"
import { Index } from "elasticlunr"

import { Layout, SearchResult } from "../components"
import { getFilteredCinemas } from "../utils/search"

import styles from "./cinesearch.module.css"

const updateBrowserUrl = (searchOptions) => {
  const stateObj = "CineSearch"
  const pageTitle = "CineSearch"

  const searchParams = Object.keys(searchOptions)
    .filter((k) => searchOptions[k])
    .map((k) => `${k}=${searchOptions[k]}`)

  const url = `/cinesearch?${searchParams.join("&")}`

  if (typeof window !== `undefined`) {
    window && window.history.pushState(stateObj, pageTitle, url)
  }
}

class CinesearchPage extends React.Component {
  constructor(props) {
    super(props)

    // Allows for searching with query params eg: /cinesearch?city=Los%20Angeles
    const queryParams = queryString.parse(this.props.location.search)

    this.state = {
      simpleSearchQuery: queryParams.simpleSearchQuery || "",
      name: queryParams.name || "",
      city: queryParams.city || "",
      country: queryParams.country || "",
      status: queryParams.status || "",
      yearOfConstruction: queryParams.yearOfConstruction || "",
      yearOfOpening: queryParams.yearOfOpening || "",
      architecturalStyle: queryParams.architecturalStyle || "",
      feature: queryParams.feature || ""
    }

    this.handleChange = this.handleChange.bind(this)
    this.resetSearch = this.resetSearch.bind(this)
    this.browserNavigationHandler = this.browserNavigationHandler.bind(this)
  }

  componentDidMount() {
    if (typeof window !== `undefined`) {
      window.addEventListener("popstate", this.browserNavigationHandler, false)
    }

    this.forceUpdate()
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.browserNavigationHandler, false)
  }

  getOrCreateIndex = () =>
    this.index
      ? this.index
      : // Create an elastic lunr index and hydrate with graphql query results
        Index.load(this.props.data.siteSearchIndex.index)

  browserNavigationHandler(event) {
    const queryParams = queryString.parse(event.target.location.search)

    const emptySearchFilters = {
      simpleSearchQuery: "",
      name: "",
      city: "",
      country: "",
      status: "",
      yearOfConstruction: "",
      yearOfOpening: "",
      architecturalStyle: "",
      feature: ""
    }

    this.setState({ ...emptySearchFilters, ...queryParams })
  }

  handleChange(evt) {
    this.setState(
      { [evt.target.name]: evt.target.value },
      updateBrowserUrl({ ...this.state, [evt.target.name]: evt.target.value })
    )
  }

  resetSearch() {
    this.setState({
      simpleSearchQuery: "",
      country: "",
      city: "",
      name: "",
      status: "",
      yearOfConstruction: "",
      yearOfOpening: "",
      architecturalStyle: "",
      feature: ""
    })
  }

  render() {
    const cinemas = this.props.data.allContentfulCinema.edges.map(
      (edge) => edge.node
    )

    const countries = cinemas.map((c) => c.country).filter((c) => c)
    const uniqCountries = Array.from(new Set(countries)).sort()
    const cities = cinemas.map((c) => c.city).filter((c) => c)
    const uniqCities = Array.from(new Set(cities)).sort()
    const allStatuses = cinemas.map((c) => c.status).filter((c) => c)
    const statuses = Array.from(new Set(allStatuses))
    const yearRanges = [
      { from: 1500, to: 1899 },
      { from: 1900, to: 1909 },
      { from: 1910, to: 1919 },
      { from: 1920, to: 1929 },
      { from: 1930, to: 1939 },
      { from: 1940, to: 1949 },
      { from: 1950, to: 1959 },
      { from: 1960, to: 1969 },
      { from: 1970, to: 1979 },
      { from: 1980, to: 1989 },
      { from: 1990, to: 1999 },
      { from: 2000, to: 2009 },
      { from: 2010, to: 2019 }
    ]

    let allArchitecturalStyles = []
    cinemas.forEach((c) => {
      c.architecturalStyle &&
        c.architecturalStyle.forEach((style) =>
          allArchitecturalStyles.push(style.name)
        )
    })
    const architecturalStyles = Array.from(
      new Set(allArchitecturalStyles)
    ).sort()

    let allFeatures = []
    cinemas.forEach((c) => {
      c.featureList &&
        c.featureList.forEach((feature) =>
          allFeatures.push(feature.description)
        )
    })
    const features = Array.from(new Set(allFeatures)).sort()
    const filteredCinemas = getFilteredCinemas(
      cinemas,
      this.state,
      this.getOrCreateIndex()
    )

    return (
      <Layout>
        <div className={styles.pageContainer}>
          <div className={styles.formInputs}>
            <div className={styles.formFields}>
              <input
                className={styles.simpleSearchField}
                name="simpleSearchQuery"
                type="text"
                value={this.state.simpleSearchQuery}
                onChange={this.handleChange}
                placeholder={"Type to search..."}
              />

              <button className={styles.formButton} onClick={this.resetSearch}>
                Reset search
              </button>
            </div>

            <details>
              <summary className={styles.summary}>Advanced search</summary>
              <div className={styles.formFieldsDetails}>
                <div className={styles.formLabelInputGroup}>
                  <label className={styles.formInputLabel} htmlFor="name">
                    Name of cinema:
                  </label>
                  <input
                    className={styles.formInput}
                    name="name"
                    type="text"
                    value={this.state.name}
                    onChange={this.handleChange}
                    placeholder={"Type to search"}
                  />
                </div>

                <div className={styles.formLabelInputGroup}>
                  <label className={styles.formInputLabel} htmlFor="city">
                    City:
                  </label>
                  <select
                    className={styles.formInput}
                    name="city"
                    value={this.state.city}
                    onChange={this.handleChange}
                  >
                    <option key="" value="" />
                    {uniqCities.map((city) => (
                      <option key={city} value={city}>
                        {city}
                      </option>
                    ))}
                  </select>
                </div>

                <div className={styles.formLabelInputGroup}>
                  <label className={styles.formInputLabel} htmlFor="country">
                    Country:
                  </label>
                  <select
                    className={styles.formInput}
                    name="country"
                    value={this.state.country}
                    onChange={this.handleChange}
                  >
                    <option value="" />
                    {uniqCountries.map((country) => (
                      <option key={country} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                </div>

                <div className={styles.formLabelInputGroup}>
                  <label className={styles.formInputLabel} htmlFor="status">
                    Status:
                  </label>
                  <select
                    className={styles.formInput}
                    name="status"
                    value={this.state.status}
                    onChange={this.handleChange}
                  >
                    <option value="" />
                    {statuses.map((status) => (
                      <option key={status} value={status}>
                        {status}
                      </option>
                    ))}
                  </select>
                </div>

                <div className={styles.formLabelInputGroup}>
                  <label
                    className={styles.formInputLabel}
                    htmlFor="yearOfConstruction"
                  >
                    Year of construction (completed):
                  </label>

                  <select
                    className={styles.formInput}
                    name="yearOfConstruction"
                    value={this.state.yearOfConstruction}
                    onChange={this.handleChange}
                  >
                    <option value="" />
                    {yearRanges.map((range) => {
                      const rangeText = `${range.from}-${range.to}`
                      return (
                        <option key={rangeText} value={rangeText}>
                          {rangeText}
                        </option>
                      )
                    })}
                  </select>
                </div>

                <div className={styles.formLabelInputGroup}>
                  <label
                    className={styles.formInputLabel}
                    htmlFor="yearOfOpening"
                  >
                    Year of opening:
                  </label>

                  <select
                    className={styles.formInput}
                    name="yearOfOpening"
                    value={this.state.yearOfOpening}
                    onChange={this.handleChange}
                  >
                    <option value="" />
                    {yearRanges.map((range) => {
                      const rangeText = `${range.from}-${range.to}`
                      return (
                        <option key={rangeText} value={rangeText}>
                          {rangeText}
                        </option>
                      )
                    })}
                  </select>
                </div>

                <div className={styles.formLabelInputGroup}>
                  <label
                    className={styles.formInputLabel}
                    htmlFor="architecturalStyle"
                  >
                    Architectural style:
                  </label>
                  <select
                    className={styles.formInput}
                    name="architecturalStyle"
                    value={this.state.architecturalStyle}
                    onChange={this.handleChange}
                  >
                    <option value="" />
                    {architecturalStyles.map((style) => (
                      <option key={style} value={style}>
                        {style}
                      </option>
                    ))}
                  </select>
                </div>

                <div className={styles.formLabelInputGroup}>
                  <label className={styles.formInputLabel} htmlFor="feature">
                    Feature:
                  </label>
                  <select
                    className={styles.formInput}
                    name="feature"
                    value={this.state.feature}
                    onChange={this.handleChange}
                  >
                    <option value="" />
                    {features.map((feature) => (
                      <option key={feature} value={feature}>
                        {feature}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </details>
          </div>

          <div className={styles.results}>
            <h2> {`Results (${[...filteredCinemas].length})`} </h2>

            <ul className={styles.resultsList}>
              {filteredCinemas.map((cinema) => (
                <SearchResult cinema={cinema} key={cinema.url} />
              ))}
            </ul>
          </div>
        </div>
      </Layout>
    )
  }
}

export default CinesearchPage

export const query = graphql`
  query {
    siteSearchIndex {
      index
    }
    allContentfulCinema {
      edges {
        node {
          id
          title
          description {
            description
          }
          url
          city
          country
          status
          yearOfConstruction
          yearOfFirstOpening
          yearOfReopening
          architecturalStyle {
            name
          }
          featureList {
            description
          }
          images {
            image {
              fluid(maxHeight: 100) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  }
`
