const isYearOfConstructionInRange = (cinema, range) => {
  const startDate = range.split("-")[0]
  const endDate = range.split("-")[1]

  return (
    cinema.yearOfConstruction >= Number(startDate) &&
    cinema.yearOfConstruction <= Number(endDate)
  )
}

const isYearOfOpeningInRange = (cinema, range) => {
  const startDate = range.split("-")[0]
  const endDate = range.split("-")[1]

  return (
    (cinema.yearOfFirstOpening >= Number(startDate) &&
      cinema.yearOfFirstOpening <= Number(endDate)) ||
    (cinema.yearOfReopening >= Number(startDate) &&
      cinema.yearOfReopening <= Number(endDate))
  )
}

const hasArchitecturalStyle = (cinema, architecturalStyle) => {
  if (!cinema.architecturalStyle) {
    return false
  }

  const styles = cinema.architecturalStyle.map((style) => style.name)
  return styles.includes(architecturalStyle)
}

const hasFeature = (cinema, feature) => {
  if (!cinema.featureList) {
    return false
  }

  const features = cinema.featureList.map((feature) => feature.description)
  return features.includes(feature)
}

const includedInSimpleSearchResults = (cinema, query, searchIndex) => {
  const results = searchIndex
    .search(query, { expand: true })
    .map(({ ref }) => ref)
  return results.includes(cinema.id)
}

export const getFilteredCinemas = (cinemas, searchParams, searchIndex) => {
  return cinemas
    .filter((cinema) =>
      searchParams.simpleSearchQuery === ""
        ? true
        : includedInSimpleSearchResults(
            cinema,
            searchParams.simpleSearchQuery,
            searchIndex
          )
    )
    .filter((cinema) =>
      cinema.title.toLowerCase().includes(searchParams.name.toLowerCase())
    )
    .filter((cinema) =>
      searchParams.country === ""
        ? true
        : cinema.country === searchParams.country
    )
    .filter((cinema) =>
      searchParams.city === "" ? true : cinema.city === searchParams.city
    )
    .filter((cinema) =>
      searchParams.status === "" ? true : cinema.status === searchParams.status
    )
    .filter((cinema) =>
      searchParams.yearOfConstruction === ""
        ? true
        : isYearOfConstructionInRange(cinema, searchParams.yearOfConstruction)
    )
    .filter((cinema) =>
      searchParams.yearOfOpening === ""
        ? true
        : isYearOfOpeningInRange(cinema, searchParams.yearOfOpening)
    )
    .filter((cinema) =>
      searchParams.architecturalStyle === ""
        ? true
        : hasArchitecturalStyle(cinema, searchParams.architecturalStyle)
    )
    .filter((cinema) =>
      searchParams.feature === ""
        ? true
        : hasFeature(cinema, searchParams.feature)
    )
}
